import { Spatialise } from "./3D/Spatialise"

type Props = {
	spatialParentID: string,
	heading: string, 
	text: string,
	alt: string,
	imageURL: string
}

type PropsFeature = {
	heading: string, 
	style: React.CSSProperties,
	text: string,
	alt: string,
	imageURL: string
}

const serviveStyles = {
	"--spatial-font-size": "15"
} as React.CSSProperties

function Feature({heading, style, text, alt, imageURL}:PropsFeature) {
	const fullURL = "assets/features/"+imageURL
	return(
		<div style={{position:"relative",height:"255px"}}>
			<div className="feature" style={style}>
				<div className="featureHeading">
					<img alt={alt} src={fullURL}/>
					<div><h2>{heading}</h2></div>
				</div>
				<p>{text}</p>
			</div>
		</div>
	)
}

function Experiences3D() {
	const heading = "Fun and informative 3D experiences"
	const text = "Keep users on your website for longer by giving them something fun to do\
	or show them products that they can manipulate in 3D as if it was physically in their hand."
	const imageURL = "experience.jpg"
	const fullURL = "assets/services/"+imageURL
	const alt = "Group of people cheering at a computer screen"

	const feat1 = ["Showcase Products", "Use realistic 3D visuals so users get the same experience as being in your shop, but without having to leave their house .", "A small container", "products.svg"]
	const feat2 = ["Memorable Experiences", "Users will associative your brand with a positive experience and will keep coming back.", "Circles in motion", "memories.svg"]
	const feat3 = ["Stand Out", "When users experience something miles better than anything else, they'll tell their friends about it.", "A shooting star", "prominence.svg"]
	return(
		<div style={{position:"relative"}}>
			<div id="blob1" className="blob" style={{top:"-20vw"}}>
				<img alt="First blob" src="assets/blobs1.svg"/>
			</div>
			<div id="blob2" className="blob" style={{left:"15%",top:"-40vw"}}>
				<img alt="Second blob" src="assets/blobs2.svg"/>
			</div>
			<div className="service">
				<div className="serviceText">
					<h1 data-spatial-position={[0,0,0]} data-spatial-parent="panel1">{heading}</h1>
					<p data-spatial-position={[0,-0.1,0]} data-spatial-parent="panel1">{text}</p>
				</div>
				<div className="serviceImageMain">
					<img data-spatial-position={[0,-0.15,0]} data-spatial-size={[0.9,0.65]} data-spatial-parent="panel1" alt={alt} src={fullURL}/>
				</div>
			</div>		
			<div className="features">
				<Feature heading={feat1[0]} style={{left:"0"}} text={feat1[1]} alt={feat1[2]} imageURL={feat1[3]}/>
				<Feature heading={feat2[0]} style={{margin:"0 auto",left:"0",right:"0"}} text={feat2[1]} alt={feat2[2]} imageURL={feat2[3]}/>
				<Feature heading={feat3[0]} style={{right:"0"}} text={feat3[1]} alt={feat3[2]} imageURL={feat3[3]}/>
			</div>
		</div>
	)
}

function Service({spatialParentID, heading, text, alt, imageURL}:Props) {
	const fullURL = "assets/services/"+imageURL
	return(
		<div className="service">
			<div className="serviceText">
				<h1 data-spatial-position={[0,0,0]} data-spatial-parent={spatialParentID}>{heading}</h1>
				<p data-spatial-position={[0,-0.1,0]} data-spatial-parent={spatialParentID} style={serviveStyles}>{text}</p>
			</div>
			<div className="serviceImage">
				<img data-spatial-position={[0,-0.15,0]} data-spatial-size={[0.9,0.65]} data-spatial-parent={spatialParentID} alt={alt} src={fullURL}/>
			</div>
		</div>
	)
}

function ServiceReverse({spatialParentID, heading, text, alt, imageURL}:Props) {
	const fullURL = "assets/services/"+imageURL
	return(
		<div className="service">
			<div className="serviceImageReverse">
				<img data-spatial-position={[0,-0.15,0]} data-spatial-size={[0.9,0.65]} data-spatial-parent={spatialParentID} alt={alt} src={fullURL}/>
			</div>
			<div className="serviceTextReverse">
				<h1 data-spatial-position={[0,0,0]} data-spatial-parent={spatialParentID}>{heading}</h1>
				<p data-spatial-position={[0,-0.1,0]} data-spatial-parent={spatialParentID} style={serviveStyles}>{text}</p>
			</div>
		</div>
	)
}

function SpatialWebsites() {
	const heading = "Spatial websites that come to life in VR headsets"
	const text = "Be among the rare brands with a website that effectively utilises 3D space when accessed through a VR headset.\
		Users no longer need to scroll, click, and navigate through pages\
	 	when those pages can be displayed all at once all around them.\
	 	This runs in mixed reality so users can also see what their kids are up to\
	 	and can cook dinner whilst viewing your website."

	const imageURL = "spatial.jpg"
	const alt = "Man viewing spatial website"

	return(
		<div style={{position:"relative"}}>
			<div id="blob3" className="blob" style={{top:"-20vw",right:"0"}}>
				<img alt="Third blob" src="assets/blobs3.svg"/>
			</div>
			<div id="blob4" className="blob" style={{top:"-5vw",left:"0"}}>
				<img alt="Fourth blob" src="assets/blobs4.svg"/>
			</div>
			<Service spatialParentID="panel2" alt={alt} heading={heading} text={text} imageURL={imageURL}/>
		</div>
	)
}

function Clothing3D() {
	const heading = "3D clothing customiser"
	const text = "Allow your customers to create clothing designs on a poseable 3D human, giving them the ability to see how the clothes will appear on their own bodies so they can purchase with confidence.\
		It helps when you can see how these outfits look while you're actually playing sport, but you can't go to a store, try on clothes, then ride a bike or kick a soccer ball.\
		With this customiser you can make the 3D human do sporting actions with the clothes you've designed."
	const imageURL = "sportswear.jpg"
	const alt = "Girl holding up customised sports top"

	return(
		<ServiceReverse spatialParentID="panel3" alt={alt} heading={heading} text={text} imageURL={imageURL}/>
	)
}

function Multiplayer() {
	const heading = "Multiplayer online worlds"
	const text = "Interacting with 3D products and characters are cool but a really engaging environment is one where you can also interact with other people visiting that website.\
		Allow users to try fun experiences with friends or strangers on the other side of the world from the safety and comfort of their own home.\
		Users will stay even longer when their friends are also there."
	const imageURL = "metaverse.jpg"
	const alt = "Girl dancing along with virtual world on computer screen"

	return(
		<div style={{position:"relative"}}>
			<div id="blob5" className="blob" style={{top:"-5vw",right:"0"}}>
				<img alt="Fifth blob" src="assets/blobs3.svg"/>
			</div>
			<Service spatialParentID="panel4" alt={alt} heading={heading} text={text} imageURL={imageURL}/>
		</div>
	)
}

export default function Services() {
	return(
		<>
			<Experiences3D/>
			<SpatialWebsites/>
			<Clothing3D/>
			<Multiplayer/>
		</>
	)
}